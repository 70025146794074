import React from 'react';
import { Outlet } from 'react-router-dom';
import Hero from './components/Hero/Hero'; 
import SecondaryBanner from './components/SecondaryBanner/SecondaryBanner';
import Navbar from './components/Navbar/Navbar';

function Layout() {
  return (
    <div>

      <Navbar /> {/* Add Navbar component here */}

      <Hero /> {/* Add Hero component here */}

      <main>
        <Outlet />
      </main>

      <SecondaryBanner /> {/* Add Hero component here */}

    </div>
  );
}

export default Layout;