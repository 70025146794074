import React from 'react';
import './SecondaryBanner.css';

const SecondaryBanner = () => {
  return (
    <section className="secondary-banner">
      <h1>We Support Kids Education</h1>
      <p>Discover the creativity of young artists!</p>
    </section>
  );
};

export default SecondaryBanner;
