import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";

// pages
import Layout from './Layout';
import About from "./pages/About";
import Contact from "./pages/Contact";
import Program from "./pages/Program";
import Home from './pages/Home';
import './App.css';


function App() {
  return (
    <BrowserRouter> 
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<Home />} />
              <Route path="/Program" element={<Program />} />
              <Route path="/About" element={<About />} />
              <Route path="/Contact" element={<Contact />} />
            </Route>
          </Routes>
    </BrowserRouter>
  );
}

export default App;