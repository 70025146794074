import React from 'react';

const About = () => {
  return (
    <div className="about">
      <h2>About Us</h2>
      <p>
      Canva Arts offers digital art lessons for children aged 10 and up, focusing on the fundamentals of design using Canva. We teach them to create a variety of digital art, including personalized greeting cards, and share techniques to enhance their designs. Their artwork is featured on our website, and we provide printing and mailing services for their greeting cards upon request.
      </p>
    </div>
  )
}

export default About;