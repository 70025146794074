import React from 'react';

const Program = () => {
  return (
    <div className="program">
      <h2>Program</h2>
      <p>
      Canva Arts offers free digital art lessons for children aged 10 and up, focusing on the fundamentals of design using Canva. We teach them to create a variety of digital art, including personalized greeting cards, and share techniques to enhance their designs. Their artwork is featured on our website, and we provide printing and mailing services for their greeting cards upon request.
      </p>
        {/* <h2>Program Schedule</h2>
        <p>
        Day: Every Wednesday, November to December 2024
        </p>
        <p>
        Time: 7pm to 8pm Mountain Day Time
        </p> */}
    </div>
  )
}

export default Program;