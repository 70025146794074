import React from 'react';
import './ProductCard.css'; // Import the CSS specific to the this section

const ProductCard = ({ product }) => {
  return (
    <div className="product-card">
      <img src={product.image_url} alt={product.title} className="product-image" />
      <h3>Artist: {product.artist}</h3>
      <p>{product.age} yrs old</p>
      {/* <div className="product-price">${product.price}</div> */}
    </div>
  )
}

export default ProductCard

